import React from 'react'
import {Link}  from 'react-router-dom'
import './style.css'

const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className={`wpo-site-footer ${props.ftClass}`}>
        <div className="wpo-upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-6 col-12">
                        <div className="widget about-widget">
                            <div className="logo widget-title">
                                <img src={props.ftLogo} alt="blog"/>
                            </div>
                            <p>Join the ranks of satisfied clients who have experienced the difference with Tech Support Inc.  Reach out today and let's take your IT infrastructure to the next level. 

</p>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/"><i className="ti-facebook"></i></Link></li>
                                <li><Link onClick={ClickHandler} to="/"><i className="ti-twitter-alt"></i></Link></li>
                                <li><Link onClick={ClickHandler} to="/"><i className="ti-instagram"></i></Link></li>
                                <li><Link onClick={ClickHandler} to="/"><i className="ti-google"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-12">
                        <div className="widget link-widget resource-widget">
                            <div className="widget-title">
                                <h3>Services</h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/service"> IT Infrastructure Management                                </Link></li>
                                <li><Link onClick={ClickHandler} to="/service">Cloud Services                                </Link></li>
                                <li><Link onClick={ClickHandler} to="/service">Technical Support & Helpdesk
                                </Link></li>
                                <li><Link onClick={ClickHandler} to="/service">System Administration</Link></li>
                                <li><Link onClick={ClickHandler} to="/service">Network Administration & Security
                                </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-2 col-md-6 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                            <li><Link onClick={ClickHandler} to="/home">Home</Link></li>
                                <li><Link onClick={ClickHandler} to="/about">About</Link></li>
                                <li><Link onClick={ClickHandler} to="/service">Service</Link></li>
                                <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-3 offset-lg-1 col-md-6 col-12">
                        <div className="widget market-widget wpo-service-link-widget">
                            <div className="widget-title">
                                <h3>Contact </h3>
                            </div>
                           
                            <div className="contact-ft">
                                <ul>
                                    <li><i className="fi flaticon-pin"></i>Level 01, 39 Fitzroy Square, London W1T 6EZ</li>
                                    <li><i className="fi flaticon-phone"></i>+447459425326</li>
                                    <li><i className="fi flaticon-mail"></i>contact@techsupportinc.co.uk</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="wpo-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="separator"></div>
                    <div className="col col-xs-12">
                        <p className="copyright">&copy; 2024 Codexer. All rights reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;