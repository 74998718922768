import React from 'react'
import './style.css'

const Newsletter = (props) => {

    const SubmitHandler = (e) => {
      e.preventDefault()
    }
    return(
        <section className={`wpo-news-letter-section ${props.nClass}`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-md-6 col-sm-12 col-12">
                       
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default Newsletter;