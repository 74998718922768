import React, { Component } from 'react';
import axios from 'axios';

class ContactForm extends Component {
    state = {
        email: '',
        subject: '',
        password: '',
        error: {},
    };

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = '';

        this.setState({
            [e.target.name]: e.target.value,
            error,
        });
    };

    submitHandler = async (e) => {
        e.preventDefault();

        const { email, subject, password, error } = this.state;

        // Validation
        if (email === '') error.email = 'Please enter your email';
        if (subject === '') error.subject = 'Please enter the subject';
        if (password === '') error.password = 'Please enter the password';

        if (error.email || error.subject || error.password) {
            this.setState({ error });
            return;
        }

        // Send email via backend
        try {
            const response = await axios.post('http://localhost:4000/send-email', {
                email,
                subject,
                password,
            });
            alert(response.data.message);
            this.setState({
                email: '',
                subject: '',
                password: '',
                error: {},
            });
        } catch (err) {
            console.error('Error:', err);
            alert('Failed to send email');
        }
    };

    render() {
        const { email, subject, password, error } = this.state;

        return (
            <div className="container">
                <h2>Send Email</h2>
                <form onSubmit={this.submitHandler}>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={this.changeHandler}
                            placeholder="Enter recipient's email"
                            className="form-control"
                        />
                        <p className="error">{error.email}</p>
                    </div>
                    <div className="form-group">
                        <label>Subject</label>
                        <input
                            type="text"
                            name="subject"
                            value={subject}
                            onChange={this.changeHandler}
                            placeholder="Enter subject"
                            className="form-control"
                        />
                        <p className="error">{error.subject}</p>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="text"
                            name="password"
                            value={password}
                            onChange={this.changeHandler}
                            placeholder="Enter the new password"
                            className="form-control"
                        />
                        <p className="error">{error.password}</p>
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Send Email
                    </button>
                </form>
            </div>
        );
    }
}

export default ContactForm;
