import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link}  from 'react-router-dom'
import tm1 from '../../images/team/img-1.jpg'
import tm2 from '../../images/team/img-2.jpg'
import tm3 from '../../images/team/img-3.jpg'
import './style.css'


class Team extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: true,
            speed: 3000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const team = [
            {
                tmImg: tm1,
                Title: 'Jenny Watmon',
                Sub: "SCG First Company",
            },
            {
                tmImg: tm2,
                Title: 'Harry Abraham',
                Sub: "SCG First Company",
            },
            {
                tmImg: tm3,
                Title: 'Ron Di-soza',
                Sub: "SCG First Company",
            },
            {
                tmImg: tm2,
                Title: 'Benjir Walton',
                Sub: "SCG First Company",
            }
        ]
        return (
            <section className="team-section ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col col-lg-6 col-12">
                        <div className="section-title">
    <h2>About Our Founder</h2>
    <p>Alex, with over 10 years of IT consulting expertise, is dedicated to delivering innovative solutions and exceptional client service.</p>
</div>

                        </div>
                    </div>
                   
                </div>
            </section>
        );
    }
}

export default Team;