import React from 'react'
import { Link } from 'react-router-dom'
import service1 from '../../images/service/img-1.png'
import service2 from '../../images/service/img-2.png'
import service3 from '../../images/service/img-3.png'
import service4 from '../../images/service/img-4.png'
import service5 from '../../images/service/img-5.png'
import service6 from '../../images/service/img-6.png'
import './style.css'

const Service = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
    }
    const services = [
        {
            title: "IT Infrastructure Management",
            details: [
                "Server and network management",
                "Virtualization (VMware, HyperV)",
                "Cloud infrastructure (Azure, Oracle Cloud, O365)",
                "Backup and disaster recovery solutions",
                "Storage management (SAN, NAS)"
            ]
        },
        {
            title: "Cloud Services",
            details: [
                "Cloud migration (Azure, IaaS, PaaS, SaaS)",
                "Identity management using Azure Active Directory",
                "Cloud security and backup (Datto, Cloud Ally)",
                "Virtual desktop infrastructure (VDI/AVD)"
            ]
        },
        {
            title: "Technical Support & Helpdesk",
            details: [
                "1st/2nd/3rd line technical support",
                "End-user support (Windows, Mac OS)",
                "Office 365 and SharePoint support",
                "Remote and onsite troubleshooting"
            ]
        },
        {
            title: "System Administration",
            details: [
                "Active Directory, DNS, DHCP, GPO management",
                "Windows Server administration",
                "PowerShell scripting for automation",
                "File server and WSUS management"
            ]
        },
        {
            title: "Network Administration & Security",
            details: [
                "LAN/WAN, MPLS, VPN setup and management",
                "Firewall and security management (Sophos, Cisco Meraki)",
                "VLAN/VLSM configurations",
                "WiFi design and deployment (CISCO, Aruba, UniFi, Ruckus)",
                "Network monitoring and performance optimization",
                "Network router and switch configuration"
            ]
        },
        {
            title: "Cybersecurity Services",
            details: [
                "Endpoint protection (Antivirus, Cisco Umbrella)",
                "Data and email security (Barracuda, Mimecast)",
                "VPN/P2P security",
                "Zero-trust architecture (Cisco)"
            ]
        },
        {
            title: "Virtualization & Backup",
            details: [
                "VMware, Citrix, Dell EMC virtualization services",
                "Backup solutions (Veeam, Datto)",
                "Disaster recovery and business continuity planning"
            ]
        },
        {
            title: "Cloud Collaboration Tools",
            details: [
                "Microsoft Teams, WebEx, Zoom setup and administration",
                "SharePoint configuration and user training",
                "Power BI implementation"
            ]
        },
        {
            title: "Project Management",
            details: [
                "IT project planning and execution",
                "Vendor management and collaboration",
                "Budget management and forecasting for IT projects"
            ]
        },
        {
            title: "Hardware & Peripheral Support",
            details: [
                "Setup and configuration of servers (Dell PowerEdge)",
                "Printer, POS, AV, and video conferencing solutions",
                "Desktop and laptop setup (Windows/Mac)"
            ]
        },
        {
            title: "Hospitality IT Solutions",
            details: [
                "IPTV, IP telephony, and CCTV setup (NVR/DVR)",
                "WiFi HSIA implementation for hotels and resorts",
                "Music and AV system support for hospitality businesses"
            ]
        },
        {
            title: "Automation & Workflow Optimization",
            details: [
                "Microsoft Power Automate workflows",
                "Zapier automation integration",
                "System and process automation for efficiency"
            ]
        },
        {
            title: "Software & Application Management",
            details: [
                "SCCM, Lansweeper, and Okta administration",
                "Integration with third-party applications (Sage, Dropbox)",
                "SaaS application management"
            ]
        },
        {
            title: "Training & Onboarding",
            details: [
                "Development of training materials for IT systems",
                "Employee onboarding and technical training (Microsoft Viva)",
                "IT training for non-technical staff"
            ]
        }
    ];
    

    const serviceWrap = [
        {
           simg: service1,
           title: "Network Security",
           des: 'Protect your data with advanced security protocols and monitoring.',
           link: "/service-single"
        },
        {
           simg: service2,
           title: "Data Backup & Recovery",
           des: 'Secure your business with reliable data backup and recovery services.',
           link: "/service-single"
        },
        {
           simg: service3,
           title: "Managed IT Services",
           des: 'Comprehensive IT support to keep your systems running smoothly.',
           link: "/service-single"
        },
        {
           simg: service4,
           title: "Cloud Solutions",
           des: 'Seamless cloud integration to enhance accessibility and scalability.',
           link: "/service-single"
        },
        {
           simg: service5,
           title: "Software Development",
           des: 'Custom software solutions for your business needs.',
           link: "/service-single"
        },
        {
           simg: service6,
           title: "IT Consulting",
           des: 'Get expert advice to optimize your IT infrastructure and improve efficiency.',
           link: "/service-single"
        },
    ];

    return (
        <div className="wpo-service-area ">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Expert IT Solutions</h2>
                            <p>Comprehensive IT services including network setup, server maintenance, and robust cybersecurity tailored to business needs.</p>
                        </div>
                     
                    </div>
                </div>
                <div className="row">
                    {serviceWrap.slice(0, 6).map((item, srv) => (
                        <div className="col-lg-4 col-md-6 col-12 custom-grid" key={srv}>
                            <div className="wpo-service-item">
                                <div className="wpo-service-icon">
                                    <img src={item.simg} alt="S-icon"/>
                                </div>
                                <div className="wpo-service-text">
                                    <h2>{item.title}</h2>
                                    <p>{item.des}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <Link to="/service" className="see-more-link" onClick={ClickHandler}>
                            See More
                        </Link>
                    </div>
                </div>

                <div className="services-section">
                <div className="section-title">
                            <h2>Our Services</h2>
                            <p>Tech Support Inc. offers IT infrastructure management, including network setup, server maintenance, and cybersecurity solutions.</p>
                        </div>
    <div className="service-grid">
      {services.map((service, index) => (
        <div key={index} className="service-item">
          <h3>{service.title}</h3>
          <ul>
            {service.details.map((detail, idx) => (
              <li key={idx}>{detail}</li>
            ))}
          </ul>
         
        </div>
      ))}
    </div>
  </div>
            </div>
        </div>
    );
}

export default Service;
