import React from 'react'
import ab from '../../images/about.36ed591a.png'
import './style.css'

const About = (props) => {
    return(
        <section className="about-page-area ">
        <div className="container">
            <div className="row">
                <div className="col col-md-8">
                    <div className="about-area">
                    <span className="who-we-are">Who We Are</span>
                    <h2>Experienced IT consultancy with tailored VIP client services.
                     </h2>
                     <p>Tech Support Inc provides specialized IT consultancy, offering personalized solutions in networking, system administration, and cloud computing for clients.</p>
                        <ul>
                            <li>Experienced IT consultancy based in Marylebone, London.</li>
                            <li>Specializes in networking, system administration, and cloud services. </li>
                            <li>Offers tailored, VIP-level support for Central London clients.</li>
                        </ul>
                    </div>
                </div>
                <div className="col col-md-4">
                    <div className="about-img">
                        <img src={ab} alt=""/>
                    </div>
                </div>
            </div>
        </div> 
    </section>
    )
}

export default About;