import React from 'react';
import ContactForm from '../ContactFrom'
import './style.css'

const Contactpage = () => {

    return(
        <section className="wpo-contact-form-map section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 col-12">
                        <div className="row">
                            <div className="col col-lg-7 col-12">
                                <div className="contact-form">
                                    <h2>Get In Touch</h2>
                                    <ContactForm/>
                                </div>                            
                            </div>
                            <div className="col col-lg-5 col-12">
                                <div className="wpo-contact-info">
                                    <div className="info-item">
                                        <h2>Level 01, 39 Fitzroy Square, London W1T 6EZ</h2>
                                        <div className="info-wrap">
                                            <div className="info-icon">
                                                <i className="fi flaticon-internet"></i>
                                            </div>
                                            <div className="info-text">
                                                <span>Office Address</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info-item">
                                        <h2>contact@techsupportinc.co.uk</h2>
                                        <div className="info-wrap">
                                            <div className="info-icon">
                                                <i className="fi flaticon-mail"></i>
                                            </div>
                                            <div className="info-text">
                                                <span>Official Mail</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info-item">
                                        <h2>+447459425326</h2>
                                        <div className="info-wrap">
                                            <div className="info-icon">
                                                <i className="fi flaticon-support-1"></i>
                                            </div>
                                            <div className="info-text">
                                                <span>Official Phone</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-lg-12">
                                <div className="contact-map">
                                <iframe 
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d542!2d-0.1394474!3d51.522665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761bb0fc106227%3A0xf98a868cbbea7a06!2sTech+Support+Inc!5e0!3m2!1sen!2sbd!4v1547528325671" 
  width="600" 
  className="map-frame"

  height="450" 
  style={{ border: "0" }} 
  allowFullScreen 
  loading="lazy" 
  referrerPolicy="no-referrer-when-downgrade">
</iframe>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     )
        
}

export default Contactpage;
